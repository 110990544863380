import React from 'react';
import Logo from '../assets/sunfocus.png';
import { LuFacebook } from "react-icons/lu";
import { FaInstagram } from "react-icons/fa6";
import { RiYoutubeLine } from "react-icons/ri";
import image6 from '../assets/Image6.png';
import { Link } from 'react-router-dom';

export const Footer = () => {
    return (
        <div className="bg-dark text-white py-8 relative z-0">
            <div className="container mx-auto px-4">
                <div className="flex flex-wrap -mx-4">
                    <div className="w-full lg:w-1/3 px-4 mb-8 lg:mb-0">
                        <img className='w-1/2 max-w-[200px] mb-4' src={Logo} alt="Sunfocuz Logo" />
                        <p className='text-sm lg:text-base mb-4'>
                            Ready to embrace solar solutions?
                            <br /> Contact us now to illuminate your
                            <br /> path to sustainable energy with Sunfocuz.
                        </p>
                        <ul className="flex space-x-4">
                            <li>
                                <a href="/" className="text-white hover:text-gray-300 transition-colors">
                                    <FaInstagram size={24} />
                                </a>
                            </li>
                            <li>
                                <a href="/" className="text-white hover:text-gray-300 transition-colors">
                                    <LuFacebook size={24} />
                                </a>
                            </li>
                            <li>
                                <a href="/" className="text-white hover:text-gray-300 transition-colors">
                                    <RiYoutubeLine size={24} />
                                </a>
                            </li>
                        </ul>
                    </div>
    
                    <div className="w-full sm:w-1/2 lg:w-1/6 px-4 mb-8 lg:mb-0">
                        <h5 className="text-lg font-semibold mb-4">Links</h5>
                        <ul className="space-y-2">
                            <li><Link to="about" className="text-white hover:text-gray-300 transition-colors">About Us</Link></li>
                            <li><Link to="services" className="text-white hover:text-gray-300 transition-colors">Services</Link></li>
                            <li><Link to="gallery" className="text-white hover:text-gray-300 transition-colors">Gallery</Link></li>
                            <li><Link to="contact" className="text-white hover:text-gray-300 transition-colors">Contact Us</Link></li>
                        </ul>
                    </div>
    
                    <div className="w-full sm:w-1/2 lg:w-1/3 px-4 mb-8 lg:mb-0">
                        <h5 className="text-lg font-semibold mb-4">Address</h5>
                        <p className='mb-4'>8/26 G MJV Mall, Pada South, Karunagappally,<br /> Karunagappally, India, Kerala</p>
                        <p>+91 92079 79999</p>
                    </div>
                </div>
    
                <div className="mt-8 lg:mt-0 lg:absolute lg:bottom-8 lg:right-8">
                    <h5 className="text-lg font-semibold mb-2">Subscribe</h5>
                    <div className="flex">
                        <input type="email" className="form-input p-2 rounded-l-md w-full sm:w-auto" placeholder="Your email" />
                        <button className="bg-white border-0 p-2 rounded-r-md">
                            <img className="w-6 h-6" src={image6} alt="Send" />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};