


import React from 'react';
import solarpanel2 from '../assets/solarpanel2_11zon.jpg';
import solarpanel4 from '../assets/solarpanel4.jpg';
import solarpanel3 from '../assets/solarpanel3_11zon.jpg';
import Rectangle64 from '../assets/Rectangle 64.jpg';
import Rectangle20 from '../assets/Rectangle20_11zon.jpg';
import Rectangle68 from '../assets/Rectangle 68.jpg';
import Rectangle69 from '../assets/Rectangle 69.jpg';
import Rectangle43 from '../assets/Rectangle43.jpeg';

export const GallerySection = () => {
    return (
        <>
            <div className="container mx-auto p-5 max-w-7xl">
                <h2 className="text-2xl text-[#000] md:text-3xl lg:text-4xl font-bold mb-6 text-start">Gallery</h2>
                <div className="flex flex-wrap gap-6">
                    <div className="flex-1 min-w-[250px]">
                        <div className="mb-4">
                            <img
                                className="w-full h-[250px] object-cover"
                                src={solarpanel2}
                                alt="Solar Panel 2"
                            />
                        </div>
                        <div className="mb-4">
                            <img
                                className="w-full h-[250px] object-cover"
                                src={Rectangle43}
                                alt="Rectangle 43"
                            />
                        </div>
                        <div className="mb-4">
                            <img
                                className="w-full h-[370px] object-cover"
                                src={Rectangle64}
                                alt="Rectangle 64"
                            />
                        </div>
                    </div>
                    <div className="flex-1 min-w-[250px]">
                        <div className="mb-4">
                            <img
                                className="w-full h-[447px] object-cover"
                                src={solarpanel3}
                                alt="Solar Panel 3"
                            />
                        </div>
                        <div>
                            <img
                                className="w-full h-[447px] object-cover"
                                src={solarpanel4}
                                alt="Solar Panel 4"
                            />
                        </div>
                    </div>
                    <div className="flex-1 min-w-[250px]">
                        <div className="mb-4">
                            <img
                                className="w-full h-[250px] object-cover"
                                src={Rectangle20}
                                alt="Rectangle 20"
                            />
                        </div>
                        <div className="mb-4">
                            <img
                                className="w-full h-[280px] object-cover"
                                src={Rectangle69}
                                alt="Rectangle 69"
                            />
                        </div>
                        <div>
                            <img
                                className="w-full h-[340px] object-cover"
                                src={Rectangle68}
                                alt="Rectangle 68"
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className=" card w-full bg-[#F16B28] p-4 flex justify-between items-center max-w-xl mx-auto mt-2 z-3 top-5">                
                <div className="text-white">
                    <h2 className="text-3xl font-bold">Smartest Way to Generate</h2>
                    <h2 className="text-3xl font-bold">Electricity</h2>
                </div>
                <button
                    className="bg-white text-[#000] mt-4 px-4 py-2 rounded  "
                >
                    Get in Touch
                </button>
            </div>
        </>
    );
};
