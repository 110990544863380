import React from 'react';
import Rectangle11 from "../assets/Rectangle11.png";
import Rectangle12 from "../assets/Rectangle12.jpeg";
import Rectangle13 from "../assets/Rectangle13.png";
import solarpanel1 from "../assets/solarpanel1.jpg";

export const Services = () => {
    const systems = [
        {
            title: "On - grid solar system",
            image: Rectangle11,
            alt: "On-grid Solar System",
            description: "Connect to the grid and harness solar energy efficiently with Sunfocuz's on-grid solar systems for a reliable and cost-effective power solution. Our on-grid solar system installation and maintenance services are highly acclaimed. Customers commend our expertise in providing seamless connections to the power grid, ensuring efficient and reliable energy solutions. Our professional team is dedicated to delivering top-quality installations and ongoing support, maximizing energy savings and sustainability."
        },
        {
            title: "Off - grid solar system",
            image: Rectangle12,
            alt: "Off-grid Solar System",
            description: "Experience energy independence wherever you are with Sunfocuz's off-grid solar systems, providing sustainable power solutions. Our off-grid solar system installation and maintenance services are widely praised. Customers value our expertise in delivering independent, reliable energy solutions, perfect for remote locations. Our skilled team ensures high-quality installations and comprehensive support, empowering customers with sustainable, self-sufficient power."
        },
        {
            title: "Hybrid Solar System",
            image: Rectangle13,
            alt: "Hybrid Solar System",
            description: "Optimize energy usage with Sunfocuz's hybrid solar systems, seamlessly integrating solar power and traditional sources. Our hybrid solar system installation and maintenance services are highly esteemed. Customers appreciate our proficiency in combining on-grid and off-grid solutions, providing reliable and flexible energy options. Our experienced team delivers top-notch installations and ongoing support, ensuring optimal performance and maximum energy efficiency."
        }
    ];

    return (
        <div className="w-full">
        <div
          className="relative h-[20rem] sm:h-[25rem] md:h-[30rem] lg:h-[40rem] bg-cover bg-center"
          style={{ backgroundImage: `url(${solarpanel1})` }}
        >
          <div className="relative w-full h-full flex items-start justify-start p-4 sm:p-6 md:p-8 lg:p-10">
            <div className="max-w-lg text-left text-white">
              <h1 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-bold animate-popin-top">Services</h1>
              <p className="text-base sm:text-lg md:text-xl lg:text-2xl mt-2 sm:mt-4 md:mt-6 lg:mt-8 animate-popin-left">
                Helping you save energy and keep the lights on
              </p>
            </div>
          </div>
        </div>
      

            <div className="container mx-auto px-4 py-8 sm:px-6 md:px-8 lg:px-10">
  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-12">
    {systems.map((service, index) => (
      <div key={index} className="flex flex-col h-full z-0 border-1 border-[#F16B28] rounded-[25px] overflow-hidden">
        <div className="flex-grow p-4 sm:p-6 md:p-8 lg:p-6">
          <h5 className="font-bold text-lg mb-3 text-center md:text-left">{service.title}</h5>
          <p className="text-gray-600 text-base sm:text-lg md:text-base lg:text-sm text-justify">
            {service.description}
          </p>
        </div>
        <img 
          className="w-full h-48 object-cover" 
          src={service.image} 
          alt={service.alt} 
        />
      </div>
    ))}
  </div>
</div>


            <div className="card w-full bg-[#F16B28] p-4 flex justify-between items-center max-w-xl mx-auto mt-2 z-3 top-5">                
                <div className="text-white">
                    <h2 className="text-3xl font-bold">Smartest Way to Generate</h2>
                    <h2 className="text-3xl font-bold">Electricity</h2>
                </div>
                <button
                    className="bg-white text-[#000] mt-4 px-4 py-2 rounded"
                >
                    Get in Touch
                </button>
            </div>
        </div>
    );
};