import React from 'react';
import quickInstallationImage from '../assets/Rectangle42.jpeg';
import periodicMaintenanceImage from '../assets/Rectangle43.jpeg';
import appropriatePricingImage from '../assets/Rectangle44.jpeg';
import fan from '../assets/Rectangle49.gif';
import Rectangle2 from '../assets/Rectangle2.jpeg';
import Rectangle38 from '../assets/Rectangle38.jpeg';
import Rectangle91 from '../assets/Rectangle91.png';
import Rectangle92 from '../assets/Rectangle92.png';
import Rectangle93 from '../assets/engi.webp';
import civi from '../assets/civi.webp'


const Card = ({ title, description, imageSrc }) => (
  <div className="bg-gray-100 rounded-lg overflow-hidden shadow-md flex flex-col">
    <div className="p-6 flex-grow">
      <h3 className="text-xl font-semibold mb-2">{title}</h3>
      <p className="text-gray-600 mb-4">{description}</p>
    </div>
    <img src={imageSrc} alt={title} className="w-full h-50 object-cover" />
  </div>
);

const TeamMember = ({ imageSrc, name, role }) => (
  <div className="relative w-full sm:w-72 h-80 rounded-lg overflow-hidden">
    <img src={imageSrc} className="w-full h-full object-cover" alt={name} />
    <div className="absolute bottom-0 left-0 right-0 bg-orange-500 bg-opacity-80 py-2 px-4 text-center">
      <h5 className="font-bold text-white">{name}</h5>
      <p className="text-white">{role}</p>
    </div>
  </div>
);

const InfoCard = ({ title, description }) => (
  <div className="w-full md:w-[400px] rounded-3xl p-6 border-2 border-orange-400">
    <h3 className="text-orange-400 text-lg font-semibold mb-3">{title}</h3>
    <p className="text-sm text-gray-700 leading-relaxed">{description}</p>
  </div>
);

export const About = () => {
  return (
    <div className="bg-white">
      <div className="h-[240px] sm:h-[320px] md:h-[400px] lg:h-[480px]">
        <img className="w-full h-full object-cover" src={fan} alt="Fan" />
      </div>

      <div className="bg-[#f6efea] py-8 sm:py-12 px-4">
        <div className="max-w-6xl mx-auto">
          <h2 className="text-3xl sm:text-5xl font-bold mb-6 sm:mb-8 text-left">
            About <span className="text-[#F16B28]">Us</span>
          </h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 sm:gap-12 lg:gap-16">
            <Card 
              title="Quick Installation"
              description="Remove ancient vents with swift installation, bringing renewable green energy to your doorstep promptly and efficiently"
              imageSrc={quickInstallationImage}
            />
            <Card 
              title="Periodic Maintenance"
              description="Our proactive maintenance services guarantee the longevity and optimal performance of your solar system, providing peace of mind"
              imageSrc={periodicMaintenanceImage}
            />
            <Card 
              title="Appropriate Pricing"
              description="We offer competitive and transparent pricing, making clean energy solutions accessible for all"
              imageSrc={appropriatePricingImage}
            />
          </div>
        </div>
      </div>

      <div className='flex flex-col lg:flex-row items-start p-5'>
        <div className="w-full lg:w-1/2 p-4 sm:p-6 md:p-8 text-justify">
          <h2 className="text-2xl sm:text-3xl font-bold mb-3 sm:mb-4">
            Reshaping Energy for the <span className="text-[#F16B28]">Future</span>
          </h2>
          <p className="mt-3 sm:mt-4 text-sm sm:text-base text-gray-600 leading-relaxed">
  We epitomize excellence in solar solutions. With a steadfast commitment to precision, innovation, and sustainability, we harness the power of the sun to illuminate a cleaner and brighter future. Our seasoned professionals bring a wealth of expertise, ensuring that every solar project reflects our dedication to quality, reliability, and a greener tomorrow.
</p>
          <a
            href="/"
            className="mt-4 sm:mt-6 inline-block bg-[#F16B28] text-white py-2 px-4 sm:px-6 rounded-full text-sm font-semibold hover:bg-[#E05A17] transition-colors"
          >
            Contact Us
          </a>
        </div>
        <div className='lg:w-1/2 p-5'>
          <img className='w-full h-auto max-w-sm rounded-lg shadow-md mx-auto' src={Rectangle2} alt="Reshaping Energy" />
        </div>
      </div>

      <div className="flex flex-col md:flex-row gap-20 justify-center p-4" style={{ marginTop: '-76px' }}>
        <InfoCard
          title="Professional & Quality"
          description="At Sunfocuz, professionalism and quality define our solar solutions. Our expert team ensures precise installations, delivering high-quality services for a sustainable future"
        />
        <InfoCard
          title="Customer Satisfaction"
          description="Sunfocuz guarantees customer satisfaction with comprehensive warranty coverage. Our proactive maintenance ensures worry-free, efficient solar solutions"
        />
      </div>

      <div className="h-[240px] sm:h-[320px] md:h-[400px] lg:h-[670px]">
        <img className="w-full h-full object-cover" src={Rectangle38} alt="Solar Panels" />
      </div>

      <div className='p-5'>
        <h2 className='text-[#F16B28] text-center font-bold text-4xl md:text-5xl lg:text-3xl py-10 px-5'>
          Team Members
        </h2>

        <div className='flex flex-wrap justify-center gap-8 sm:gap-16 p-5 mb-20'>
          <TeamMember imageSrc={civi} name="Eugene M. Reed" role="Supervisor" />
          <TeamMember imageSrc={Rectangle92} name="Martin C. Sparks" role="Chief Executive" />
          <TeamMember imageSrc={Rectangle93} name="Kyle L. Jones" role="Worker" />
          <TeamMember imageSrc={Rectangle91} name="James D. Castro" role="Worker" />
        </div>
      </div>
    </div>
  );
};

export default About;