import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import sunfocus_png from '../../assets/sunfocus.png';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    
    <nav className="bg-white shadow-md w-full">
      <div className=" px-4 sm:px-6 lg:px-8">
  <div className="flex justify-between items-center h-16">
    <div className="flex-shrink-0 flex items-center">
      <img className="h-12 w-auto sm:h-16 " src={sunfocus_png} alt="SUNFOCUZ Logo" />
    </div>
    <div className="hidden md:flex md:items-center md:space-x-4 lg:space-x-8">
      <Link to="" className="text-gray-900 hover:text-orange-500 px-3 py-2 text-sm font-medium">Home</Link>
      <Link to="about" className="text-gray-500 hover:text-orange-500 px-3 py-2 text-sm font-medium">About us</Link>
      <Link to="services" className="text-gray-500 hover:text-orange-500 px-3 py-2 text-sm font-medium">Services</Link>
      <Link to="gallery" className="text-gray-500 hover:text-orange-500 px-3 py-2 text-sm font-medium">Gallery</Link>
      <Link to="contact" className="text-gray-500 hover:text-orange-500 px-3 py-2 text-sm font-medium">Contact us</Link>
    </div>
    <div className="hidden md:flex md:items-center md:space-x-4 md:justify-end">
      <a href="tel:+919207979699" className="bg-orange-500 text-white px-4 py-2 rounded-full text-sm font-medium hover:bg-orange-600 transition">
        Call now: +919207979699
      </a>
    </div>





    
    <div className="md:hidden flex items-center">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-orange-500"
        aria-controls="mobile-menu"
        aria-expanded="false"
      >
        <span className="sr-only">Open main menu</span>
        {isOpen ? (
          <svg className="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
          </svg>
        ) : (
          <svg className="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
          </svg>
        )}
      </button>
    </div>
  </div>
</div>


      <div className={`${isOpen ? 'block' : 'hidden'} md:hidden`} id="mobile-menu">
        <div className="px-2 pt-2 pb-3 space-y-1">
          <Link to="" className="bg-orange-50 text-orange-700 block px-3 py-2 rounded-md text-base font-medium">Home</Link>
          <Link to="about" className="text-gray-500 hover:bg-orange-50 hover:text-orange-700 block px-3 py-2 rounded-md text-base font-medium">About us</Link>
          <Link to="services" className="text-gray-500 hover:bg-orange-50 hover:text-orange-700 block px-3 py-2 rounded-md text-base font-medium">Services</Link>
          <Link to="gallery" className="text-gray-500 hover:bg-orange-50 hover:text-orange-700 block px-3 py-2 rounded-md text-base font-medium">Gallery</Link>
          <Link to="contact" className="text-gray-500 hover:bg-orange-50 hover:text-orange-700 block px-3 py-2 rounded-md text-base font-medium">Contact us</Link>
        </div>
        <div className="pt-4 pb-3 border-t border-gray-200">
          <div className="flex items-center px-4">
            <a href="tel:+919207979699" className="bg-orange-500 text-white w-full px-4 py-2 rounded-full text-sm font-medium hover:bg-orange-600 transition text-center">
              Call now: +91 92079 79699
            </a>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;