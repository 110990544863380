import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Navbar from './components/Navbar/Navbar';

import { About } from './components/About';
import { Footer } from './components/Footer';
import Home from './components/Home';
import { Services } from './components/Services';
import { GallerySection } from './components/GallerySection';
import ContactUs from './components/Contact'; // Corrected import statement

const App = () => {
  return (
    <Router>
      <div>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/services" element={<Services />} />
          <Route path="/gallery" element={<GallerySection />} />
          <Route path="/contact" element={<ContactUs />} /> {/* Ensure the path matches */}
        </Routes>
      </div>
      <Footer />
    </Router>
  );
};

export default App;
