import React, { useState, useRef } from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaMapMarkerAlt, FaPhone, FaEnvelope } from 'react-icons/fa';
import headerImage from '../assets/Rectangle 49.jpg';


const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    options: '',
    message: ''
  });
  const [errors, setErrors] = useState({});
  const mapRef = useRef(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: ''
    }));
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.name) newErrors.name = 'Name is required';
    if (!formData.email || !/\S+@\S+\.\S+/.test(formData.email)) newErrors.email = 'Valid email is required';
    if (!formData.phone || !/^\d{10}$/.test(formData.phone)) newErrors.phone = 'Phone number must be 10 digits';
    if (!formData.options) newErrors.options = 'This field is required';
    if (!formData.message) newErrors.message = 'Message is required';
    return newErrors;
  };

  const handleSubmit = async (event, formData, setErrors) => {
    event.preventDefault();

    try {
      const res = await fetch('https://6zwqa32s3e.execute-api.us-east-1.amazonaws.com/production/contact', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (!res.ok) {
        const errorData = await res.json();
        throw new Error(errorData.message || 'Network response was not ok');
      }

      const data = await res.json();
      console.log('Response:', data);
      return data;

    } catch (error) {
      setErrors(error.message);
      console.error('Error:', error);
      return { status: 'error', message: error.message };
    }
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    try {
      const response = await handleSubmit(event, formData, setErrors);
      console.log('Response from server:', response);

      if (response.status === 'verification_sent') {
        toast.info('A verification email has been sent to your email address. Please check your inbox to confirm your email address.');
      } else if (response.status === 'email_sent') {
        toast.success('Thanks for contacting us, we will be in touch with you soon.');
        setFormData({
          name: '',
          email: '',
          phone: '',
          options: '',
          message: ''
        });
        setErrors({});
      }
    } catch (error) {
      setErrors({ general: 'An error occurred while submitting the form.' });
      console.error('Error:', error);
    }
  };

  const scrollToMap = () => {
    mapRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div className="font-sans">
      <div className="relative w-full overflow-hidden">
        <img src={headerImage} alt="Contact Us" className="w-full h-auto object-cover" />
      </div>

      <section className="relative flex flex-col md:flex-row md:flex-wrap justify-around p-5 bg-white container mx-auto">
        <div className="absolute top-6 left-2 md:left-5">
          <h1 className="text-3xl md:text-4xl font-bold">
            Contact<span className="ml-1 text-[#F16B28]">US</span>
          </h1>
        </div>
        {[
          { icon: FaMapMarkerAlt, title: "Location", content: "18/26 G, MJV Mall, Karunagappally,\nPada South, Lalaji Junction,\nKarunagappalli, Kerala 690518", onClick: scrollToMap },
          { icon: FaPhone, title: "Phone", content: "+91 92075 76893", href: "tel:+919207576893" },
          { icon: FaEnvelope, title: "E-mail", content: "info@sunfocus.in", href: "mailto:info@sunfocus.in" }
        ].map((item, index) => (
          <div key={index} className="text-center mb-8 md:mb-0 flex-1 min-w-[250px] mt-16 md:mt-20">
            <div className="relative inline-flex justify-center items-center">
              <div className="absolute inset-0 w-full h-full">
                <div className="absolute inset-0 border border-orange-200 rounded-full animate-ripple-fast"></div>
                <div className="absolute inset-0 border-2 border-orange-300 rounded-full animate-ripple-med"></div>
                <div className="absolute inset-0 border-4 border-orange-400 rounded-full animate-ripple-slow"></div>
              </div>
              <a 
                href={item.href} 
                onClick={item.onClick} 
                className="relative z-10 p-3 bg-white rounded-full cursor-pointer"
              >
                <item.icon className="text-3xl text-orange-500" />
              </a>
            </div>
            <h3 className="my-2 text-lg font-semibold">{item.title}</h3>
            <p className="px-4 whitespace-pre-line">{item.content}</p>
          </div>
        ))}
      </section>

      <div className="w-full lg:w-1/2 flex justify-center mt-8 lg:mt-32">
        <div className="w-full max-w-lg shadow-2xl p-4 rounded-3xl bg-white">
          <h2 className="text-xl md:text-xl text-center font-bold mb-4">
            
          
            <ToastContainer />
            Fill the Form
          </h2>
          <form onSubmit={handleFormSubmit}>
            <div className="grid grid-cols-1 gap-4">
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                placeholder="Your Name"
                className="bg-gray-100 p-2 rounded-lg w-full"
              />
              {errors.name && <p className="text-red-500">{errors.name}</p>}
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="E-Mail Address"
                className="bg-gray-100 p-2 rounded-lg w-full"
              />
              {errors.email && <p className="text-red-500">{errors.email}</p>}
              <input
                type="tel"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                placeholder="Phone Number"
                className="bg-gray-100 p-2 rounded-lg w-full"
              />
              {errors.phone && <p className="text-red-500">{errors.phone}</p>}
              <select
                name="options"
                value={formData.options}
                onChange={handleChange}
                className="bg-gray-100 p-2 rounded-lg w-full"
              >
                <option value="">I'm Looking for</option>
                <option value="option1">Option 1</option>
                <option value="option2">Option 2</option>
                <option value="option3">Option 3</option>
              </select>
              {errors.options && <p className="text-red-500">{errors.options}</p>}
              <textarea
                name="message"
                value={formData.message}
                onChange={handleChange}
                placeholder="Message"
                className="bg-gray-100 p-2 rounded-lg h-24 w-full"
              ></textarea>
              {errors.message && <p className="text-red-500">{errors.message}</p>}
            </div>
            <button
              type="submit"
              className="bg-orange-500 text-white rounded-xl py-2 px-4 mt-4 w-full border border-orange-500"
            >
              Send Message
            </button>
            {errors.general && <p className="text-red-500 mt-4">{errors.general}</p>}
          </form>
          
        </div>
        
      </div>
      

      <div ref={mapRef} className="container mx-auto px-4 my-8">
        <MapContainer center={[9.0579, 76.5363]} zoom={13} className="h-64 md:h-96 w-full rounded-lg shadow-md">
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          />
          <Marker position={[9.0579, 76.5363]}>
            <Popup>Sunfocus Energies Pvt Ltd</Popup>
          </Marker>
        </MapContainer>
      </div>
    </div>
  );
};

export default ContactUs;