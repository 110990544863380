import React from "react";
import  { useState,useEffect } from "react";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import HeroImg from "../assets/images/hero-img.jpg";
import solarpanels from '../assets/images/solar-panels.jpg';
import Rect1 from "../assets/images/Rectangle 11.jpg";
import Rect2 from "../assets/pannels.jpg";
import Rect3 from "../assets/images/Rectangle 13.jpg";
import Recta1 from "../assets/Rectangle11.png";
import Recta2 from "../assets/pannels.jpg";
import Recta3 from "../assets/Rectangle13.png";
import Icon1 from "../assets/Image2.png"; 
import Icon2 from "../assets/Image3.png";
import Icon3 from "../assets/Image4.png";
import Icon4 from "../assets/Image5.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const AnimatedNumber = ({ target }) => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    const increment = target / 100; // Adjust the speed of counting by changing the denominator
    const duration = 2000; // Duration of the animation in milliseconds
    const intervalTime = duration / (target / increment);

    const interval = setInterval(() => {
      setCount((prevCount) => {
        const nextCount = prevCount + increment;
        if (nextCount >= target) {
          clearInterval(interval);
          return target;
        }
        return nextCount;
      });
    }, intervalTime);

    return () => clearInterval(interval);
  }, [target]);

  return <span>{Math.floor(count)} +</span>;
};
const SunForce = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: true,
    centerMode: true,
    centerPadding: "0",
  };

  return (
    <div className="bg-[#fff5ee] p-4 md:p-8 z-0">
      <h2 className="text-2xl md:text-3xl font-bold text-center text-orange-500 mb-4 mt-10 md:mt-20">Testimonials</h2>
      <p className="text-center text-gray-600 mb-8 text-sm md:text-base">Discover what our clients are saying about Sunfocuz</p>
      <div className="max-w-sm md:max-w-md mx-auto">
        <Slider {...settings}>
          <div className="p-3">
            <div className="bg-white p-4 md:p-6 rounded-[25px] shadow-md">
              <div className="w-16 h-16 md:w-20 md:h-20 rounded-full mx-auto mb-4 bg-purple-600 flex items-center justify-center">
                <svg className="w-8 h-8 md:w-12 md:h-12 text-white" fill="currentColor" viewBox="0 0 20 20">
                  <path fillRule="evenodd" d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z" clipRule="evenodd" />
                </svg>
              </div>
              <h4 className="text-lg md:text-xl font-semibold mb-1 text-center">prekash.T.P</h4>
              <p className="text-xs md:text-sm text-gray-500 mb-4 text-center">CEO</p>
              <p className="text-xs md:text-sm text-gray-700 text-justify">
                Our solar product installation and maintenance services are celebrated for their exceptional quality, reliability, and sustainable solutions. Customers praise our knowledgeable and professional team for delivering efficient, customized installations and ongoing support, ensuring optimal performance and satisfaction.
              </p>
            </div>
          </div>

          <div className="p-3">
            <div className="bg-white p-4 md:p-6 shadow-md rounded-[25px]">
              <div className="w-16 h-16 md:w-20 md:h-20 rounded-full mx-auto mb-4 bg-pink-600 flex items-center justify-center">
                <svg className="w-8 h-8 md:w-12 md:h-12 text-white" fill="currentColor" viewBox="0 0 20 20">
                  <path fillRule="evenodd" d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z" clipRule="evenodd" />
                </svg>
              </div>
              <h4 className="text-lg md:text-xl font-semibold mb-1 text-center">Suneesh.v.s</h4>
              <p className="text-xs md:text-sm text-gray-500 mb-4 text-center">CTO</p>
              <p className="text-xs md:text-sm text-gray-700 text-justify">
                Our solar product installation and maintenance services are celebrated for their exceptional quality, reliability, and sustainable solutions. Customers praise our knowledgeable and professional team for delivering efficient, customized installations and ongoing support, ensuring optimal performance and satisfaction.
              </p>
            </div>
          </div>

          <div className="p-3">
            <div className="bg-white p-4 md:p-6 shadow-md rounded-[25px]">
              <div className="w-16 h-16 md:w-20 md:h-20 rounded-full mx-auto mb-4 bg-blue-500 flex items-center justify-center">
                <svg className="w-8 h-8 md:w-12 md:h-12 text-white" fill="currentColor" viewBox="0 0 20 20">
                  <path fillRule="evenodd" d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z" clipRule="evenodd" />
                </svg>
              </div>
              <h4 className="text-lg md:text-xl font-semibold mb-1 text-center">Alice Kanjarathingal</h4>
              <p className="text-xs md:text-sm text-gray-500 mb-4 text-center">COO</p>
              <p className="text-xs md:text-sm text-gray-700 text-justify">
                Our solar product installation and maintenance services are renowned for their quality, dependability, and eco-friendly solutions. Our clients appreciate our professional and responsive team, the seamless installation process, and the continuous support that ensures their systems operate at peak efficiency.
              </p>
            </div>
          </div>
        </Slider>
      </div>
    </div>
  );
};

const Home = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    options: '',
    message: ''
  });
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: ''
    }));
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.name) newErrors.name = 'Name is required';
    if (!formData.email || !/\S+@\S+\.\S+/.test(formData.email)) newErrors.email = 'Valid email is required';
    if (!formData.phone || !/^\d{10}$/.test(formData.phone)) newErrors.phone = 'Phone number must be 10 digits';
    if (!formData.options) newErrors.options = 'This field is required';
    if (!formData.message) newErrors.message = 'Message is required';
    return newErrors;
  };

  const handleSubmit = async (event, formData, setErrors) => {
    event.preventDefault();

    try {
      const res = await fetch('https://6zwqa32s3e.execute-api.us-east-1.amazonaws.com/production/contact', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (!res.ok) {
        const errorData = await res.json();
        throw new Error(errorData.message || 'Network response was not ok');
      }

      const data = await res.json();
      console.log('Response:', data);
      return data;  // Return the response data

    } catch (error) {
      setErrors(error.message);
      console.error('Error:', error);
      return { status: 'error', message: error.message };  // Return error status
    }
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    try {
      const response = await handleSubmit(event, formData, setErrors);
      console.log('Response from server:', response);

      if (response.status === 'verification_sent') {
        toast.info('A verification email has been sent to your email address. Please check your inbox to confirm your email address.');
      } else if (response.status === 'email_sent') {
        toast.success('Thanks for contacting us, we will be in touch with you soon.');
        setFormData({
          name: '',
          email: '',
          phone: '',
          options: '',
          message: ''
        });
        setErrors({});
      }
    } catch (error) {
      setErrors({ general: 'An error occurred while submitting the form.' });
      console.error('Error:', error);
    }
  };
  return (
    <div className="overflow-x-hidden">
        <ToastContainer />
    {/* Hero Section */}
    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 items-center p-4 md:p-6">
      {/* Left Column */}
      <div className="space-y-4 order-1 md:order-1">
        {/* Top Box */}
        <div className="bg-gray-200 p-4 md:p-6 rounded-3xl shadow-lg">
          <h1 className="text-xl md:text-2xl lg:text-4xl font-bold px-2 md:px-8">
            Discover Clean Solar <br />
            <span className="text-orange-500">Energy Solutions</span>
          </h1>
          <p className="mt-4 text-sm md:text-base lg:text-lg text-black">
            Empowering homes and communities with sustainable, transforming
            homes and communities with cutting-edge solar solutions.
          </p>
        </div>
        
        {/* Grid for 350+ and 500+ cards (same for all screen sizes) */}
        <div className="grid grid-cols-2 gap-3">
      {/* 350+ Card */}
      <div className="bg-orange-500 p-4 rounded-3xl shadow-md">
        <h2 className="text-xl md:text-2xl font-bold text-white">
          <AnimatedNumber target={350} />
        </h2>
        <h2 className="text-sm md:text-lg font-bold text-white">Happy Customers</h2>
        <hr className="border-white border-2 my-2" />
        <p className="text-white text-xs md:text-sm">
          We have served over 350 + customers with expert solar product
          installation and maintenance, providing reliable and sustainable
          energy solutions.
        </p>
      </div>

      {/* 500+ Card */}
      <div className="bg-gray-500 p-4 rounded-3xl shadow-lg">
        <h2 className="text-xl md:text-2xl font-bold text-white">
          <AnimatedNumber target={500} />
        </h2>
        <h2 className="text-sm md:text-lg font-bold text-white">Happy Customers</h2>
        <hr className="border-white border-2 my-2" />
        <p className="text-white text-xs md:text-sm">
          With over 500 satisfied customers, we provide expert
          installation and maintenance of solar products, ensuring
          reliable and sustainable energy solutions.
        </p>
          </div>
        </div>
      </div>
      
      {/* Right Column */}
      <div className="flex items-center justify-center mt-4 md:mt-0 order-2 md:order-2">
        <img
          src={HeroImg}
          alt="Solar Panels"
          className="object-contain h-48 md:h-64 lg:h-[500px]"
        />
      </div>
    </div>

      {/* Services Section */}
      <section
        className="py-8 md:py-16 px-4 md:px-8 lg:px-40"
        style={{
          backgroundImage: `linear-gradient(rgba(128, 128, 128, 0.2), rgba(128, 128, 128, 0.2)), url(${solarpanels})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',

        
        }}
      >
        <h2 className="text-xl md:text-2xl lg:text-3xl font-bold text-center mb-4">
          Our <span className="text-orange-500">Services</span>
        </h2>
        <p className="text-gray-600 text-center mb-8 max-w-2xl mx-auto text-sm md:text-base">
          Sunfocuz delivers expert solar product installation, ensuring seamless integration for homes and businesses seeking reliable and sustainable energy solutions.
        </p>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 max-w-5xl mx-auto">
          <div className="bg-white rounded-3xl overflow-hidden">
            <h1 className="text-center font-semibold text-base md:text-lg p-4">
              On-Grid solar systems
            </h1>
            <div className="p-4">
              <p className="text-start text-sm md:text-base">
                Connect to the grid and harness solar energy efficiently with Sunfocuz's on-grid solar systems for a reliable and cost-effective power solution
              </p>
            </div>
            <button className="text-orange-500 font-semibold flex items-center mx-4 my-1 text-sm md:text-base">
              Read more <span className="ml-2">→</span>
            </button>
            <div className="h-28 md:h-36">
              <img
                src={Rect1}
                className="w-full h-full object-cover object-bottom mt-4 md:mt-7"
                alt="On-Grid Solar System"
              />
            </div>
          </div>

          <div className="bg-white rounded-3xl overflow-hidden">
            <h1 className="text-center font-semibold text-base md:text-lg p-4">
              Off-Grid solar systems
            </h1>
            <div className="p-4">
              <p className="text-start text-sm md:text-base">
                Experience energy independence wherever you are with Sunfocuz's off-grid solar systems, providing sustainable power solutions
              </p>
            </div>
            <button className="text-orange-500 font-semibold flex items-center mx-4 my-1 text-sm md:text-base">
              Read more <span className="ml-2">→</span>
            </button>
            <div className="h-28 md:h-36">
              <img
                src={Rect2}
                className="w-full h-full object-cover object-bottom mt-4 md:mt-7"
                alt="Off-Grid Solar System"
              />
            </div>
          </div>

          <div className="bg-white rounded-3xl overflow-hidden">
            <h1 className="text-center font-semibold text-base md:text-lg p-4">
              Hybrid solar systems
            </h1>
            <div className="p-4">
              <p className="text-start text-sm md:text-base">
                Optimize energy usage with Sunfocuz's hybrid solar systems, seamlessly integrating solar power and traditional sources
              </p>
            </div>
            <button className="text-orange-500 font-semibold flex items-center mx-4 my-1 text-sm md:text-base">
              Read more <span className="ml-2">→</span>
            </button>
            <div className="h-28 md:h-36">
              <img
                src={Rect3}
                className="w-full h-full object-cover object-bottom mt-4 md:mt-7"
                alt="Hybrid Solar System"
              />
            </div>
          </div>
        </div>
      </section>
      <div className="bg-gray-700 py-8 md:py-16 px-4 md:px-8 lg:px-0 flex flex-col lg:flex-row">
  {/* Left Side */}
  <div className="w-full lg:w-1/2 lg:pr-8 flex flex-col justify-center lg:ml-24 mt-8 lg:mt-32">
    <h2 className="text-orange-500 text-2xl md:text-4xl mb-4 font-medium">
      Why choose us
    </h2>
    <h3 className="text-xl md:text-3xl text-white font-bold mb-4">
      We are Building a <br /> Sustainable Future
    </h3>
    <p className="text-white mb-8">
      At Sunfocuz, we stand out for our unwavering commitment to
      excellence, offering tailored solar solutions backed by expertise,
      quality assurance, and a passion for a sustainable future.
    </p>
    <p className="text-white mb-8">
      Trust Sunfocuz for prompt and reliable inspection and repair
      services, ensuring your solar system operates at peak performance
      with our expert care and attention to detail.
    </p>
    <h4 className="text-orange-500 text-xl md:text-2xl mb-4">Solar Leaks</h4>
    <p className="text-white mb-8">
      Our skilled technicians swiftly identify and repair leaks,
      safeguarding your solar investment and maintaining peak system
      efficiency.
    </p>
    <h4 className="text-orange-500 text-xl md:text-2xl mb-4">Solar Electricals</h4>
    <p className="text-white mb-8">
      Experience seamless solar electrical solutions with Sunfocuz,
      ensuring safe and efficient electrical setups for your solar
      systems.
    </p>
    <button className="bg-orange-500 text-white rounded-xl py-2 px-4 w-32">
      Read more
    </button>
  </div>

  {/* Right Side */}
  <div className="w-full lg:w-1/2 flex justify-center mt-8 lg:mt-32">
    <div className="w-full max-w-lg shadow-2xl p-4 rounded-3xl bg-white">
      <h2 className="text-xl md:text-xl text-center font-bold mb-4">
        Fill the Form
      </h2>
      <form onSubmit={handleFormSubmit}>
        <div className="grid grid-cols-1 gap-4">
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            placeholder="Your Name"
            className="bg-gray-100 p-2 rounded-lg w-full"
          />
          {errors.name && <p className="text-red-500">{errors.name}</p>}
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            placeholder="E-Mail Address"
            className="bg-gray-100 p-2 rounded-lg w-full"
          />
          {errors.email && <p className="text-red-500">{errors.email}</p>}
          <input
            type="tel"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            placeholder="Phone Number"
            className="bg-gray-100 p-2 rounded-lg w-full"
          />
          {errors.phone && <p className="text-red-500">{errors.phone}</p>}
          <select
            name="options"
            value={formData.options}
            onChange={handleChange}
            className="bg-gray-100 p-2 rounded-lg w-full"
          >
            <option value="">I'm Looking for</option>
            <option value="option1">Option 1</option>
            <option value="option2">Option 2</option>
            <option value="option3">Option 3</option>
          </select>
          {errors.options && <p className="text-red-500">{errors.options}</p>}
          <textarea
            name="message"
            value={formData.message}
            onChange={handleChange}
            placeholder="Message"
            className="bg-gray-100 p-2 rounded-lg h-24 w-full"
          ></textarea>
          {errors.message && <p className="text-red-500">{errors.message}</p>}
        </div>
        <button
          type="submit"
          className="bg-orange-500 text-white rounded-xl py-2 px-4 mt-4 w-full border border-orange-500"
        >
          Send Message
        </button>
        {errors.general && <p className="text-red-500 mt-4">{errors.general}</p>}
      </form>
    </div>
  </div>
</div>


      {/* Stats Section */}
      <div className="grid grid-cols-2 md:grid-cols-4 gap-4 md:gap-7 mb-12 mt-20 md:mt-44 mx-auto max-w-5xl px-4 sm:px-6 lg:px-8">
  <div className="text-center p-4 border border-orange-500 rounded-[25px] w-full">
  
    <img src={Icon1} alt="Happy Customers" className="mx-auto mb-2 w-12 h-12 md:w-16 md:h-16" />
    <h2 className="text-lg md:text-xl lg:text-2xl font-bold text-orange-500"><AnimatedNumber target={350} /></h2>
    <p className="text-xs md:text-sm lg:text-base text-gray-600">Happy Customers</p>
  </div>
  <div className="text-center p-4 border border-orange-500 rounded-[25px] w-full">
    <img src={Icon2} alt="5 Star Reviews" className="mx-auto mb-2 w-12 h-12 md:w-16 md:h-16" />
    <h2 className="text-lg md:text-xl lg:text-2xl font-bold text-orange-500"><AnimatedNumber target={300} /></h2>
    <p className="text-xs md:text-sm lg:text-base text-gray-600">5 Star Reviews</p>
  </div>
  <div className="text-center p-4 border border-orange-500 rounded-[25px] w-full">
    <img src={Icon3} alt="Team Members" className="mx-auto mb-2 w-12 h-12 md:w-16 md:h-16" />
    <h2 className="text-lg md:text-xl lg:text-2xl font-bold text-orange-500"><AnimatedNumber target={20} /></h2>
    <p className="text-xs md:text-sm lg:text-base text-gray-600">Team Members</p>
  </div>
  <div className="text-center p-4 border border-orange-500 rounded-[25px] w-full">
    <img src={Icon4} alt="Installations" className="mx-auto mb-2 w-12 h-12 md:w-16 md:h-16" />
    <h2 className="text-lg md:text-xl lg:text-2xl font-bold text-orange-500"><AnimatedNumber target={500} /></h2>
    <p className="text-xs md:text-sm lg:text-base text-gray-600">Installations</p>
  </div>
</div>



      {/* Latest Works Section */}
      <div className="py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-7xl mx-auto">
          <div className="text-center mb-8">
            <h2 className="text-xl md:text-2xl lg:text-3xl font-bold mb-4">
              Latest <span className="text-orange-500">Works</span>
            </h2>
            <p className="text-xs md:text-sm lg:text-base text-gray-600">
              Explore Sunfocuz's latest works, showcasing cutting-edge solar installations and
              maintenance projects that exemplify our commitment to precision, sustainability, and
              client satisfaction.
            </p>
          </div>
          
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
            <div className="aspect-w-4 aspect-h-3">
              <img
                src={Recta1}
                alt="Solar panels on a roof"
                className="object-cover w-full h-full rounded-[25px]"
              />
            </div>
            <div className="aspect-w-4 aspect-h-3">
              <img
                src={Recta2}
                alt="Ground-mounted solar array"
                className="object-cover w-full h-full rounded-[25px]"
              />
            </div>
            <div className="aspect-w-4 aspect-h-3">
              <img
                src={Recta3}
                alt="Single solar panel installation"
                className="object-cover w-full h-full rounded-[25px]"
              />
            </div>
          </div>
        </div>
      </div>

      <SunForce />

      {/* FAQ Section */}
      <div className="bg-white p-4 md:p-8 max-w-4xl mx-auto">
        <div className="flex justify-center items-center pb-4 mb-6">
          <h1 className="text-lg md:text-xl lg:text-2xl font-bold text-black">
            Frequently Asked <span className="text-orange-500">Questions</span>
          </h1>
        </div>
        <div className="space-y-4 md:space-y-6">
          <div className="border-1 border-orange-500 p-4 rounded-[25px]">
            <h2 className="text-base md:text-lg font-semibold text-black mb-2">
              What services does Sunfocuz provide?
            </h2>
            <p className="text-xs md:text-sm lg:text-base text-gray-600">
              Sunfocuz specializes in precision on-grid and off-grid solar system installations, proactive maintenance, and hybrid solar solutions for homes and businesses.
            </p>
          </div>
          <div className="border-1 border-orange-500 p-4 rounded-[25px]">
            <h2 className="text-base md:text-lg font-semibold text-black mb-2">
              What sets Sunfocuz apart in solar maintenance services?
            </h2>
            <p className="text-xs md:text-sm lg:text-base text-gray-600">
              Sunfocuz stands out for its commitment to excellence, offering prompt and reliable inspection and repair services by skilled technicians to ensure optimal solar system performance.
            </p>
          </div>
          <div className="border-1 border-orange-500 p-4 rounded-[25px]">
            <h2 className="text-base md:text-lg font-semibold text-black mb-2">
              How can I schedule a consultation for solar installation or maintenance?
            </h2>
            <p className="text-xs md:text-sm lg:text-base text-gray-600">
              Contact us through our website or give us a call, and our dedicated team will assist you in scheduling a personalized consultation at your convenience.
            </p>
          </div>
          <div className="border-1 border-orange-500 p-4 rounded-[25px]">
            <h2 className="text-base md:text-lg font-semibold text-black mb-2">
              Can Sunfocuz address solar leaks?
            </h2>
            <p className="text-xs md:text-sm lg:text-base text-gray-600">
              Yes, our experienced technicians promptly identify and repair solar leaks, safeguarding your investment and maintaining peak system efficiency.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;